.responsive_navbar {
    display: none;
}

@media (max-width: 565px) {
    .responsive_navbar {
        display: block;
        max-width: 100%;
        position: relative;
        z-index: 999;
    }

    section.main_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .admission_year_btn {
        display: none;
    }
}

@media (max-width: 768px) {
    .responsive_navbar {
        display: block;
        max-width: 100%;
        position: relative;
        z-index: 999;
        padding: 20px 0px;
    }
}

.responsive_navbar_section {
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff;
}

.navbar_left {
    width: 40%;
}

.navbar_right {
    width: 80%;
    display: flex;
    justify-content: flex-end;
}

.responsive_menu_wrapper {
    position: fixed;
    top: 0px;
    width: 80%;
    height: 100%;
    background: #eee;
    z-index: 99;
    padding: 60px 20px;
    display: block;
    right: 0;

    transition: all 0.3s ease 0s;
    box-shadow: -60px 0 0 100px #0006;
    overflow-y: auto;
    max-height: 100vh;
}

.close_btn {
    position: absolute;
    height: 3px;
    right: 20px;
    top: 10px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.resp_menu_items li {
    padding: 10px 20px;
    border-top: 1px solid #ffffff;
    display: flex;
    justify-content: space-between;
}

.resp_menu_items li:hover {
    background: rgb(204, 198, 198);
}

.resp_menu_items li a {
    color: black;
}

.resp_menu_items li:hover,
.resp_menu_items li a:hover {
    color: rgb(236, 62, 62);
}

.resp_dropdown_list {
    overflow-y: auto;
    max-height: 100vh;
    background: #e3dcdca1;
}

.resp_dropdown_list li a:hover,
.resp_dropdown_list li:hover {
    color: rgb(236, 62, 62);
}

.resp_dropdown_list img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.sub_dropdown_n {
    background-color: #ada7a7;
}