@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

.tableuniversitydetail table,
th,
td {
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
}

.tableuniversitydetail table thead th {
  background-color: #fd781a;
  padding: 10px 0px;
  color: #fff;
}

.tableuniversitydetail table tbody tr td {
  padding: 10px 5px;
  /* overflow-x: hidden; */
}

body {
  overflow-x: hidden;
}