.study_abroad {

    background: url("../images/banner/sa_homepage_background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    height: 336px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}

.study_abroad h3 {
    font-size: 30px;
    font-weight: 900;
    line-height: 60px;
}

.specification_list {
    display: flex;
    align-items: center;
    gap: 20px;
}

.speci_list {
    display: flex;
    align-items: center;
    gap: 10px;
}

.coll_card {
    box-shadow: 0 0 20px #d0d0d0;
    position: relative;
    background-color: #fff;
    margin: 10px;
    padding: 10px;
}

.coll_card img {
    height: 200px;
}

.coll_card .course_info {
    color: #af4a23;
    margin: 5px;
    font-size: 15px;
    font-weight: bold;
}

.filter_section {

    background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 15px #c9c9c959;
    margin-right: 20px;
    padding: 10px;
    color: #000;

}

.filter_tit {
    background: #f5f5f5;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 #0003;
    color: #f71a1a;
    margin: 5px 0;
    padding: 10px;
}

.filter_tit {
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

li.select_item {
    border: 1px solid #dadada;
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 #ebebeb96;
    display: flex;
    gap: 20px;
    margin: 4px;
    cursor: pointer;
    padding: 10px;
}

.checkbox {
    border: 2px solid orange;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.checkbox.selected {
    background-color: #ffc107;
}

.apply_btn {
    background-color: #fcaa11;
    /* Green background */
    border: none;
    /* No border */
    color: white;
    /* White text */
    padding: 10px 20px;
    /* Padding */
    text-align: center;
    /* Center text */
    text-decoration: none;
    /* No underline */
    display: inline-block;
    /* Inline-block element */
    font-size: 16px;
    /* Font size */
    cursor: pointer;
    /* Pointer cursor on hover */
    border-radius: 5px;
    /* Rounded corners */
    transition: background-color 0.3s ease;
    /* Smooth background color transition */
}


.know_more {
    background-color: #411f68;
    /* Green background */
    border: none;
    /* No border */
    color: white;
    /* White text */
    padding: 10px 20px;
    /* Padding */
    text-align: center;
    /* Center text */
    text-decoration: none;
    /* No underline */
    display: inline-block;
    /* Inline-block element */
    font-size: 16px;
    /* Font size */
    cursor: pointer;
    /* Pointer cursor on hover */
    border-radius: 5px;
    /* Rounded corners */
    transition: background-color 0.3s ease;
    /* Smooth background color transition */
    margin: 10px 0px;
}

.apply_btn:hover {
    background-color: #45a049;
}


.clear_all {
    display: flex;
    justify-content: end;
    cursor: pointer;
}


@media(max-width:767px) {
    .specification_list {
        display: none;
    }
}