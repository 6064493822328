.main-header {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}


/* .header-left {
    width: 30%;
}

.header-right {
    width: 30%;
} */

.header-right ul {
    display: flex;
    gap: 15px;
    align-items: center;
}

.header-right {
    flex-basis: content;
}

.submenu__list {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    top: 80px;
    float: left;
    min-width: 10rem;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    text-align: left;
    font-size: 1rem;
    /* visibility: hidden; */
    z-index: 9999;
    display: block !important;
}

.submenu__list li {
    padding: .2rem 0;
}


.header-right li a {
    color: black;
    margin-right: 5px;
}

.app_btn {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.header.sticky-form {
    position: fixed;
    top: 0;
    /* Change to 'bottom: 0' if you want the form to stick at the bottom */
    width: 100%;
    background-color: white;
    /* Adjust as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: Add shadow for better visibility */
    z-index: 9;
    /* Adjust to make sure it's above other content */
}
@media (max-width:767px) {
    .header {
        display: none;
    }
}