.feature_card {
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 10px;
}


.feature_card h4 {
    font-size: 16px;
}

.feature_card img {
    width: 50px;
}

.course_icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid rgba(232, 232, 232, 0.4);
    padding: 7px;
}

.course_card {
    padding: 10px;
    border: 1px solid #f2f2f2;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.course_card h4 {
    font-size: 16px;
}


.country_card {
    margin: 10px;
}


.country_card img:hover {
    filter: brightness(.5);
}


/* Form Container */
.form-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #892feb;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact_card h4 {
    color: #fff;
    font-weight: bold;
    text-align: center;
}

/* Form Group */
.form-group {
    margin-bottom: 10px;
}

/* Label Styling */
.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #fff;
}

/* Input Styling */
.form-group input,
textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

/* Input Focus State */
.form-group input:focus,
textarea:focus {
    border-color: #007BFF;
    outline: none;
}

/* Submit Button */
button {
    width: 100%;
    padding: 12px;
    background-color: #007BFF;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button Hover State */
button:hover {
    background-color: #0056b3;
}



.contact_card {
    background-color: #8869e7;
    padding: 10px;
}


.testimonial_card {
    background-color: #579de7;
    padding: 30px;
    height: 630px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonial_area {
    padding: 20px;
    width: 100%;
}


.testimonial_area p {
    color: #fff;
}

@media (max-width:767px) {

    .feature_card h4 {
        font-size: 14px;
    }

    .course_card h4 {
        font-size: 14px;
    }

    h3 {
        font-size: 24px;
    }



}


/* Make sure the video covers the entire background */
.video-container {
    position: relative;
    top: 0;
    left: 0;
    height: 700px;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    padding: 20px;
    /* Ensures the video is behind the content */
}

#background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: cover;
    /* Ensures the video covers the background */
    z-index: -1;
    filter: brightness(60%);
    /* Optional: darken the video for contrast */
}

.content {
    position: relative;
    z-index: 1;
    color: #fff;
    text-align: left;
    top: 100%;
    transform: translateY(-50%);
    font-family: Arial, sans-serif;
    padding: 30px;
}

h1 {
    font-size: 3rem;
}

p {
    font-size: 1rem;
}

.left-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 60%;
    /* Adjust this width to cover more or less of the screen */
    background-color: rgba(255, 255, 255, 0.7);
    /* Semi-transparent white overlay */
    z-index: 0;
    /* Ensures the overlay is on top of the video, but behind the content */
}

@media (max-width:767px) {
    .content {
        position: relative;
        z-index: 1;
        color: #fff;
        text-align: left;
        top: 50%;
        transform: translateY(-50%);
        font-family: Arial, sans-serif;
        /* padding: 30px; */
        width: 100%;
    }


    h1 {
        font-size: 1.5rem;
    }

    p {
        font-size: 0.9rem;
    }

    .video-container {
        height: 400px;
        padding: 0px;
        /* Ensures the video is behind the content */
    }



}