.about_us {

    background: url("../images/banner/sa_homepage_background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    height: 500px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    
}

.about_desc {
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
}

.keys_card {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 5px;
}

.contact_desc {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 500px;
}

.contact_desc li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social_media {
    display: flex;
    align-items: center;
    gap: 10px;
}
@media (max-width:767px) {
    .about_us {
        height: 700px;
        padding: 0px;
    }
}