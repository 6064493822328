.footer {
    background-color: rgb(57 73 133);
    ;
    padding: 30px 10px 10px 10px;
    margin-top: 30px;
    color: #fff;
}

.footer li {
    margin: 5px 0px;
}

.footer li a {
    color: #fff;
}


.footer_menu_list {
    display: flex;
    align-items: center;
}

@media (max-width:767px) {
    .footer_list {
        margin-bottom: 20px;
    }
}


ul.lab-ul li {
    margin: 10px 0px;
}

ul.lab-ul svg {
    color: rgb(241 150 37)
}

.social_media_grp {
    display: flex;
    gap: 20px;
}

.footer p {
    font-size: 1.1rem;
}