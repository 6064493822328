.padding-20 {
    padding: 20px;
}

.text-blue {
    color: #0400ff;
}

.font-size18 {
    font-size: 18px !important;
}

.font-bold {
    font-weight: bold;
}

.font-size13 {
    font-size: 13px !important;
}

.text-black {
    color: #000;
}

.study_abroad_section_wrapper {

    position: relative;
}



.p-2 {
    padding: 10px;
}

.study_abroad_section_wrapper span,
h5 {
    padding: 5px 0px;
}

.panel_section .panel-heading {
    padding: 10px 15px;
    background-color: #ff8800;
    display: block;
    width: 100%;
    color: #fff;
}

.panel_section {
    margin-left: 20px;
}

.panel_intro {
    margin-right: 20px;
    /* position: fixed; */
    top: 80px;
    /* Adjust based on your header height */
    bottom: 20px;
    /* Adjust based on footer or desired spacing */
    z-index: 5;
    /* Ensure it's above other content but below header/footer */
    right: 0;

    position: sticky;

}

.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " ";
}

.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " ";
}

.margin-bottom-30 {
    margin-bottom: 30px;
}


.g-brd-gray-light-v4 {
    border-color: 1px solid rgb(222, 222, 222) !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.g-brd-bottom {
    border-bottom: 1px solid transparent !important;
}

.width-100pct {
    width: 100%;
}

.g-color-gray-dark-v2 {
    color: #333 !important;
}

.padding-right-10 {
    padding-right: 10px;
}

.panel_section {
    background-color: #fff;
}

.unlogo {
    position: relative;
    margin-bottom: 20px;
}

.unlogo img {
    width: 100%;
}

.sub_logo {
    position: absolute;
    bottom: -30px;
    display: flex;
    justify-content: center;
    left: 70px;
    padding: 3px;
        background: #f5f5f5;
        border-radius: 50%;
        border: 5px solid #fff;
}

.sub_logo img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.modal_btn {
    cursor: pointer;
    width: 90%;
    margin: 0 !important;
    background: #0487f3;
    color: #fff !important;
    height: 48px;
    line-height: 48px;
    border: 1px solid #0487f3;
    color: #0487f3;
    display: inline-block;
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
    border-radius: 50px;
    margin: 5px;
    text-decoration: none;
    font-size: 14px;
}


/* Overlay for modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content box */
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Form styles */
.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="tel"],
input[type="email"],
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

textarea {
    resize: none;
    height: 100px;
}

/* Buttons */
.form-buttons {
    display: flex;
    justify-content: space-between;
}

.submit-btn,
.close-modal-btn,
.open-modal-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-btn {
    background-color: #4CAF50;
    color: white;
}

.close-modal-btn {
    background-color: #f44336;
    color: white;
}

.open-modal-btn {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.open-modal-btn:hover,
.submit-btn:hover,
.close-modal-btn:hover {
    opacity: 0.8;
}

.close_btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: right;
    background: #c3bbbb;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
}

.modal-content .form-group label {
    color: #000;
}

@media(max-width:767px) {
    .panel_section {
        margin-left: 0px;
    }

    .panel_intro {
        margin-right: 0px;
        margin-bottom: 200px;
        position: relative;
    }
.sub_logo {
    bottom: -20px;
    display: flex;
    justify-content: center;
    left: 90px;

}
}