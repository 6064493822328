.foot-fixed {
    background-color: #fff;
    bottom: 0;
    box-shadow: 1px -1px 3px #a7a7a7;
    display: none;
    padding: 20px 5px;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 9;
}


.foot-fixed a {
    color: #000 !important;
}

.foot-fixed svg {
    color: #000 !important;
}

.cm-wd-25 a {
    color: #000;
}


/* The Modal (background) */
.footer_fixed.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1000;
    /* Sit on top */
    left: 0;
    top: 100px;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    background-color: rgba(0, 0, 0, 0.5);
    /* Black with opacity */
}

.footer_fixed .modal-content {
    background-color: white;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 10px;
    border: 1px solid #888;
    width: 100%;
    /* Could be more or less, depending on screen size */
}

.footer_fixed .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width:767px) {

    .foot-fixed {
        display: block;
        padding: 25px;
    }

}

.contact__page_card {
    margin: 20px;
}