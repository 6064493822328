.career_web_banner {
    background-image: url(../images/career.webp);
    background-position: center;
    height: 450px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.career_web_overlay {
    background: transparent linear-gradient(270deg, rgba(28, 31, 42, .8) 45%, hsla(210, 2%, 56%, .8) 65%, hsla(60, 4%, 85%, .6) 75%, hsla(60, 4%, 85%, 0)) 0 0 no-repeat padding-box;
    opacity: .5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    /* z-index: 2; */
}


.career_web_banner .career-content {
    color: #fff;
    height: 100%;
    padding: 20px;
}

.career_web_banner .career-content h1 {
    color: #fff;
    height: 100%;
    font-size: 30px;
}

.career-content p {
    color: #fff;
    height: 100%;
    font-size: 20px;
}


.career_section {
    padding-top: 30px;
}

.card-blk {
    width: 320px !important;
    border-radius: 10px;
    padding: 10px;
    background-color: #fafafa;
}

.featured_service_area {
    margin-top: 20px;
}

.carr_team_card {
    padding: 20px;
}

.carr_team_card img {
    height: 250px;
    width: 250px;
    border-radius: 50%;
}


.carr_team_card img {
    margin-bottom: 20px;
}

.career_contact_sec {
    margin-bottom: 20px;
}

.career_con_box {
    border: 1px solid rgb(243, 227, 227);
    padding: 20px;
    margin-top: 20px;
}

.carrer_loc_map {
    padding: 20px;
}